html, body {
  margin: 0;
  height: 100vh;
  font-family: sans-serif;
}

.editor-container {
  height: 100%;
  flex: 6;
}

#editor {
  width: 100%;
  height: 100%;
}
